import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ResetSurveyAnswerListState,
  ResetSurveyQuestionListState,
  // getEngagementSurveyQuestionList,
  getSurveyAnswerList,
  getSurveyQuestionList,
} from "../../store/action/SurveyAction";
import { useLocation } from "react-router";
import axios from "axios";
import {
  FETCH_BACK_QUESTION_DATA_SUCCESS,
  FETCH_QUESTION_DATA_SUCCESS,
} from "../../store/constants/SurveyConstant";
import { Link } from "react-router-dom";
import { Toast } from "primereact/toast";
import { getLabelList } from "../../store/action/MetaAction";

const Survey = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const location = useLocation();
  const toast = React.useRef(null);
  const { surveyCode } = state ?? {};

  const surveyNameId = state && state.surveyId;

  const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(
    parseInt(sessionStorage.getItem("currentQuestionIndex")) || 0
  );

  const [ansData, setAnsData] = React.useState({});
  const [activeemoji, setActiveEmoji] = React.useState("");
  const [emojiWidth, setEmojiWidth] = React.useState(50);
  const [emojiHeight, setEmojiHeight] = React.useState(50);

  const [isFormValid, setIsFormValid] = React.useState(false);
  const [isFormValidError, setIsFormValidError] = React.useState("");
  const [firstquestionSuccess, setFirstQuestionSuccess] = React.useState(false);
  const [surveyCompleted, setSurveyCompleted] = React.useState(false);
  const [moodSurveyCompleted, setMoodSurveyCompleted] = React.useState(false);

  const [selectedOptionIds, setSelectedOptionIds] = React.useState([]);
  const [response, setResponse] = React.useState("");

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState("");

  const [backButtonLoading, setBackButtonLoading] = React.useState(false);
  const [moodButtonLoading, setMoodButtonLoading] = React.useState(false);
  const [nextButtonLoading, setNextButtonLoading] = React.useState(false);

  const { surveyQuestionListData } = useSelector(
    (state) => state.surveyQuestionList
  );

  const { surveyAnswerListData } = useSelector(
    (state) => state.surveyAnswerList
  );
  const { labelListData } = useSelector((state) => state.labelList);

  const [selectedAnswers, setSelectedAnswers] = React.useState(
    JSON.parse(sessionStorage.getItem("selectedAnswers"))
  );

  const [emoji, setEmoji] = React.useState();

  const vUniqueCode = sessionStorage.getItem("vUniqueCode");

  React.useEffect(() => {
    const storedAnswers = sessionStorage.getItem("selectedAnswers");
    const storedQuestionIndex = sessionStorage.getItem("currentQuestionIndex");

    if (storedAnswers) {
      setSelectedAnswers(JSON.parse(storedAnswers));
    }

    if (storedQuestionIndex) {
      setCurrentQuestionIndex(parseInt(storedQuestionIndex));
    }
  }, []);

  React.useEffect(() => {
    sessionStorage.setItem("selectedAnswers", JSON.stringify(selectedAnswers));
  }, [selectedAnswers]);

  const handleOnChangeChecked = React.useCallback(
    (questionId, selectedValue) => {
      setSelectedAnswers((prevAnswers) => ({
        ...prevAnswers,
        [questionId]: selectedValue,
      }));
      setIsFormValidError("");
    },
    []
  );

  // const handleSubmit = (event) => {
  //   event.preventDefault();

  //   const unansweredQuestions = [];
  //   const selectedQuestionIds = [];
  //   const selectedOptionIds = [];

  //   surveyQuestionListData.data.questions.forEach((question, questionId) => {
  //     const selectedValue = document.querySelector(
  //       `input[name="flexRadioDefault1${questionId}"]:checked`
  //     );

  //     if (!selectedValue) {
  //       unansweredQuestions.push(questionId + 1);
  //     } else {
  //       const optionId = parseInt(selectedValue.id, 10);
  //       selectedQuestionIds.push(question.iQuestionId);
  //       selectedOptionIds.push(optionId);
  //     }
  //   });

  //   const answerData = surveyQuestionListData && surveyQuestionListData.data;
  //   setAnsData(answerData);

  //   if (
  //     answerData &&
  //     Object.keys(answerData).length > 0 &&
  //     unansweredQuestions.length === 0
  //   ) {
  //     setNextButtonLoading(true);
  //     dispatch(
  //       getSurveyAnswerList({
  //         iCategoryId:
  //           answerData &&
  //           answerData.category &&
  //           answerData.category.iCategoryId,
  //         iSubCategoryId:
  //           answerData &&
  //           answerData.sub_category &&
  //           answerData.sub_category.iSubCategoryId
  //             ? answerData &&
  //               answerData.sub_category &&
  //               answerData.sub_category.iSubCategoryId
  //             : "",
  //         iQuestionId: selectedQuestionIds,
  //         iOptionId: selectedOptionIds,
  //         participantVUniquieCode: sessionStorage.getItem("vUniqueCode"),
  //       })
  //     );

  //     setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  //     sessionStorage.setItem(
  //       "selectedAnswers",
  //       JSON.stringify(selectedAnswers)
  //     );
  //     const prev = sessionStorage.getItem("currentQuestionIndex")
  //       ? parseInt(sessionStorage.getItem("currentQuestionIndex"))
  //       : 0;

  //     const updatedIndex = prev + 1;

  //     sessionStorage.setItem("currentQuestionIndex", updatedIndex);

  //     setCurrentQuestionIndex(updatedIndex);

  //     setIsFormValid(true);
  //     setFirstQuestionSuccess(true);

  //     window.scrollTo({ top: 0, behavior: "smooth" });

  //     toast.current.show({
  //       severity: "success",
  //       summary: "All questions answered successfully.",
  //       life: 500,
  //       className: "custom-toast",
  //     });
  //   } else {
  //     setIsFormValid(false);

  //     toast.current.show({
  //       severity: "error",
  //       summary: "All questions must be answered.",
  //       life: 1500,
  //       className: "custom-toast-error",
  //     });
  //   }

  //   setNextButtonLoading(false);
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const unansweredQuestions = [];
    const selectedQuestionIds = [];
    const selectedOptionIds = [];

    surveyQuestionListData.data.questions.forEach((question, questionId) => {
      const selectedValue = document.querySelector(
        `input[name="flexRadioDefault1${questionId}"]:checked`
      );

      if (!selectedValue) {
        unansweredQuestions.push(questionId + 1);
      } else {
        const optionId = parseInt(selectedValue.id, 10);
        selectedQuestionIds.push(question.iQuestionId);
        selectedOptionIds.push(optionId);
      }
    });

    const answerData = surveyQuestionListData && surveyQuestionListData.data;
    setAnsData(answerData);

    if (
      answerData &&
      Object.keys(answerData).length > 0 &&
      unansweredQuestions.length === 0
    ) {
      try {
        setNextButtonLoading(true);

        await dispatch(
          getSurveyAnswerList({
            iCategoryId: answerData?.category?.iCategoryId,
            iSubCategoryId: answerData?.sub_category?.iSubCategoryId || "",
            iQuestionId: selectedQuestionIds,
            iOptionId: selectedOptionIds,
            participantVUniquieCode: sessionStorage.getItem("vUniqueCode"),
          })
        );

        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
        sessionStorage.setItem(
          "selectedAnswers",
          JSON.stringify(selectedAnswers)
        );

        const prev = sessionStorage.getItem("currentQuestionIndex")
          ? parseInt(sessionStorage.getItem("currentQuestionIndex"))
          : 0;
        const updatedIndex = prev + 1;
        sessionStorage.setItem("currentQuestionIndex", updatedIndex);
        setCurrentQuestionIndex(updatedIndex);

        setIsFormValid(true);
        setFirstQuestionSuccess(true);

        window.scrollTo({ top: 0, behavior: "smooth" });

        toast.current.show({
          severity: "success",
          summary: "All questions answered successfully.",
          life: 500,
          className: "custom-toast",
        });
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "An error occurred. Please try again.",
          life: 1500,
          className: "custom-toast-error",
        });
      }
    } else {
      setIsFormValid(false);
      toast.current.show({
        severity: "error",
        summary: "All questions must be answered.",
        life: 1500,
        className: "custom-toast-error",
      });
    }

    setNextButtonLoading(false);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const values = {
          SurveyUniqueCode: state && state.surveyCode,
          ParticipantUniqueCode: vUniqueCode,
        };

        if (
          Object.keys(values).length > 0 &&
          values.SurveyUniqueCode !== null &&
          values.ParticipantUniqueCode !== null
        ) {
          await dispatch(getSurveyQuestionList(values));
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        setError("Error fetching data");
      }
    };

    fetchData();
  }, [vUniqueCode, state, dispatch]);

  React.useEffect(() => {
    const fetchQuestionData = async () => {
      if (
        !surveyCompleted &&
        firstquestionSuccess &&
        surveyAnswerListData &&
        surveyAnswerListData.data &&
        surveyAnswerListData.data.survey_status === "continue"
      ) {
        const values = {
          SurveyUniqueCode: state && state.surveyCode,
          ParticipantUniqueCode: vUniqueCode,
          iCategoryId: surveyAnswerListData.data.iCategoryId,
          iSubCategoryId: surveyAnswerListData.data.iSubCategoryId
            ? surveyAnswerListData.data.iSubCategoryId
            : "",
        };

        try {
          let response;
          setNextButtonLoading(true);

          response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/question/get_all_question_data`,
            values
          );

          if (!surveyCompleted) {
            dispatch({
              type: FETCH_QUESTION_DATA_SUCCESS,
              payload: response.data,
            });
          }
        } catch (err) {
          // console.log("err", err);
        } finally {
          setNextButtonLoading(false);
        }
      } else if (
        surveyAnswerListData &&
        surveyAnswerListData.data &&
        surveyAnswerListData.data.survey_status === "completed"
      ) {
        sessionStorage.setItem("surveyCompleted", "true");
        setSurveyCompleted(true);
        dispatch(ResetSurveyQuestionListState());
        dispatch(ResetSurveyAnswerListState());
      }
    };

    fetchQuestionData();
  }, [
    dispatch,
    firstquestionSuccess,
    surveyAnswerListData,
    state,
    vUniqueCode,
    surveyCompleted,
  ]);

  const handleBackButtonClick = async () => {
    setCurrentQuestionIndex((prevIndex) => Math.max(0, prevIndex - 1));

    const values = {
      SurveyUniqueCode: state && state.surveyCode,
      ParticipantUniqueCode: vUniqueCode,
      iCategoryId:
        surveyQuestionListData &&
        surveyQuestionListData.data &&
        surveyQuestionListData.data.category &&
        surveyQuestionListData.data.category.iCategoryId,
      iSubCategoryId:
        surveyQuestionListData &&
        surveyQuestionListData.data &&
        surveyQuestionListData.data.sub_category &&
        surveyQuestionListData.data.sub_category.iSubCategoryId
          ? surveyQuestionListData &&
            surveyQuestionListData.data &&
            surveyQuestionListData.data.sub_category &&
            surveyQuestionListData.data.sub_category.iSubCategoryId
          : "",
      vFlag: "History",
    };
    try {
      setBackButtonLoading(true);
      let responseData;

      responseData = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/question/get_all_question_data`,
        values
      );

      dispatch({
        type: FETCH_BACK_QUESTION_DATA_SUCCESS,
        payload: responseData.data,
      });
    } catch (err) {
      // console.log("err", err);
    } finally {
      setBackButtonLoading(false);
    }

    const prevIndex = parseInt(
      sessionStorage.getItem("currentQuestionIndex"),
      10
    );
    const updatedIndex = Math.max(0, prevIndex - 1);
    sessionStorage.setItem("currentQuestionIndex", updatedIndex);
    setCurrentQuestionIndex(updatedIndex);
  };

  React.useEffect(() => {
    if (surveyCompleted) {
      sessionStorage.removeItem("selectedAnswers");
      sessionStorage.removeItem("currentQuestionIndex");
    }
  }, [surveyCompleted]);

  const handleEmojiClick = (optionId) => {
    setSelectedOptionIds([optionId]);
    setActiveEmoji(optionId);
  };

  const handleEmojiSubmit = async (event) => {
    event.preventDefault();

    const values = {
      iSurveyId: surveyNameId,
      iQuestionId:
        surveyQuestionListData &&
        surveyQuestionListData.data &&
        surveyQuestionListData.data.questions.map((item) => item.iQuestionId),
      iOptionId: selectedOptionIds,
      participantVUniquieCode: vUniqueCode,
    };

    try {
      setMoodButtonLoading(true);
      let response;
      response = response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/question/answer`,
        values
      );

      setResponse(response);

      if (
        response &&
        response.data &&
        response.data.status === 200 &&
        response.data.data &&
        response.data.data.survey_status === "completed"
      ) {
        setMoodSurveyCompleted(true);
        sessionStorage.setItem("surveyCompleted", "true");
        toast.current.show({
          severity: "success",
          summary:
            "Super, and thanks for your rapid feedback. Have a brilliant day 😉",
          life: 3000,
          className: "custom-toast",
        });
      } else {
        setMoodSurveyCompleted(false);
      }
      setTimeout(() => {
        setMoodButtonLoading(false);
      }, 2000);
    } catch (err) {
      setMoodButtonLoading(false);
      // console.log(error);
    }
  };

  const surveyCompletedOne =
    sessionStorage.getItem("surveyCompleted") || "false";

  const surveyPath = location && location.pathname.slice(1);

  React.useEffect(() => {
    dispatch(
      getLabelList({
        vPage: surveyPath,
      })
    );
  }, [dispatch, surveyPath]);

  const toSentenceCase = (text) => {
    if (!text) return ""; // Handle empty text cases
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  // const surveySubHeading =
  //   labelListData &&
  //   labelListData.code === 200 &&
  //   labelListData.data &&
  //   labelListData.data.find(
  //     (title) => title.vLabel === "LBL_SURVEY_SUB_HEADING"
  //   )?.vText;

  const surveySubHeading =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    toSentenceCase(
      labelListData.data.find(
        (title) => title.vLabel === "LBL_SURVEY_SUB_HEADING"
      )?.vText || ""
    );

  const surveyInstruction =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_SURVEY_INSTRUCTION"
    )?.vText;

  const surveycompletedMessage =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_SURVEY_COMPLETED_SUCCESS_MESSAGE"
    )?.vText;

  return (
    <div>
      <section
        class="servay-section bg-image"
        style={{ backgroundImage: "url(assets/images/about-bg.png)" }}
      >
        {state && state.moodType === "Yes" ? (
          <>
            <Toast ref={toast} />
            <div>
              {surveyQuestionListData &&
                surveyQuestionListData.code === 200 &&
                surveyQuestionListData.data && (
                  <div className="container">
                    <div className="row">
                      {surveyQuestionListData.data &&
                      surveyQuestionListData.data.questions &&
                      !moodSurveyCompleted ? (
                        surveyQuestionListData.data.questions.map(
                          (question, questionId) => (
                            <div className="col-xl-10 mx-auto" key={questionId}>
                              <>
                                <div
                                  className="text-center que-title"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "30px",
                                  }}
                                >
                                  {question.vQuestion}
                                </div>
                                <div
                                  className="text-center"
                                  style={{ marginBottom: "10px" }}
                                >
                                  {surveyQuestionListData.data.options.map(
                                    (option, optId) => (
                                      <>
                                        <img
                                          key={optId}
                                          src={option.vMoodOption}
                                          alt="moodimg"
                                          width={
                                            activeemoji === option.iMoodOptionId
                                              ? "100"
                                              : "50"
                                          }
                                          height={
                                            activeemoji === option.iMoodOptionId
                                              ? "100"
                                              : "50"
                                          }
                                          style={{
                                            margin: "5px",
                                          }}
                                          onClick={() =>
                                            handleEmojiClick(
                                              option.iMoodOptionId
                                            )
                                          }
                                        />
                                      </>
                                    )
                                  )}
                                </div>

                                <div className="text-center">
                                  {moodButtonLoading ? (
                                    <>
                                      <button
                                        class="page-btn"
                                        type="button"
                                        disabled
                                      >
                                        <span
                                          class="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                        Submitting...
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      {/* <div> */}
                                      <p
                                        style={{
                                          fontSize: "10px",
                                          marginTop: "20px",
                                        }}
                                      >
                                        Select an Emoji - then press
                                        the submit button.
                                      </p>
                                      {/* </div> */}

                                      <button
                                        class="page-btn"
                                        onClick={handleEmojiSubmit}
                                        style={{ marginTop: "20px" }}
                                      >
                                        Submit
                                      </button>
                                    </>
                                  )}
                                </div>
                              </>
                            </div>
                          )
                        )
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                )}
            </div>
            {surveyCompletedOne === "true" && (
              <div className="row mx-auto">
                <div className="text-center col-xl-10 mx-auto">
                  <h4 style={{ color: "green" }} className="inner-title">
                    {surveycompletedMessage}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Link class="page-btn" to="/dashboard">
                        Continue
                      </Link>
                    </div>
                  </h4>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {loading && !surveyQuestionListData ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "100px",
                  marginBottom: "100px",
                }}
              >
                {loading && (
                  <img
                    src="/assets/gif/350.gif"
                    className="loading-gif"
                    alt="Loading..."
                  />
                )}
              </div>
            ) : (
              <>
                <Toast ref={toast} />
                {!loading &&
                !error &&
                surveyQuestionListData &&
                surveyQuestionListData.code === 200 &&
                surveyQuestionListData.data ? (
                  <div class="container">
                    <div class="row">
                      <div class="col-xl-10 mx-auto">
                        {surveyQuestionListData.data &&
                          surveyQuestionListData.data.category &&
                          surveyQuestionListData.data.sub_category && (
                            <div class="feature-head text-center">
                              <h1 class="main-title">
                                <span>
                                  {
                                    surveyQuestionListData?.data?.category
                                      ?.vCategory
                                  }
                                  {surveyQuestionListData?.data?.sub_category
                                    ?.vSubCategory
                                    ? " : "
                                    : ""}
                                </span>
                                <span style={{ color: "#ae2573" }}>
                                  {
                                    surveyQuestionListData?.data?.sub_category
                                      ?.vSubCategory
                                  }
                                </span>
                              </h1>

                              <p>{surveySubHeading}</p>
                              <p class="highlight-desc">{surveyInstruction}</p>
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="scoll-label">
                      Scroll right for the options
                    </div>
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          {surveyQuestionListData.data &&
                          surveyQuestionListData.data.options ? (
                            <tr>
                              <th></th>
                              {surveyQuestionListData.data.options.map(
                                (option, optId) => (
                                  <th key={optId}>{option.vOption}</th>
                                )
                              )}
                            </tr>
                          ) : null}
                        </thead>
                        <tbody>
                          {surveyQuestionListData.data &&
                          surveyQuestionListData.data.questions ? (
                            surveyQuestionListData.data.questions.map(
                              (question, questionId) => (
                                <tr key={questionId}>
                                  <td>{question.vQuestion}</td>
                                  {surveyQuestionListData.data.options.map(
                                    (option, optionId) => (
                                      <td class="text-center" key={optionId}>
                                        <input
                                          type="radio"
                                          class="form-check-input"
                                          name={`flexRadioDefault1${questionId}`}
                                          id={option.iOptionId}
                                          value={option.vOption}
                                          checked={
                                            selectedAnswers &&
                                            Object.keys(selectedAnswers)
                                              .length > 0
                                              ? selectedAnswers[
                                                  question.iQuestionId
                                                ] === option.vOption
                                              : false
                                          }
                                          onChange={() =>
                                            handleOnChangeChecked(
                                              question.iQuestionId,
                                              option.vOption
                                            )
                                          }
                                        />
                                      </td>
                                    )
                                  )}
                                </tr>
                              )
                            )
                          ) : (
                            <div className="col-12">
                              <p>No Data Found</p>
                            </div>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div class="text-center">
                      {currentQuestionIndex > 0 && (
                        <>
                          {backButtonLoading ? (
                            <>
                              <button class="page-btn" type="button" disabled>
                                <span
                                  class="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                Loading...
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                className="page-btn"
                                onClick={handleBackButtonClick}
                              >
                                Back
                              </button>
                            </>
                          )}
                        </>
                      )}
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {nextButtonLoading ? (
                        <>
                          {" "}
                          <button class="page-btn" type="button" disabled>
                            <span
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Loading...
                          </button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <button class="page-btn" onClick={handleSubmit}>
                            Next
                          </button>{" "}
                          <></>
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="row mx-auto">
                    <div className="text-center col-xl-10 mx-auto">
                      {/* {console.log("surveyCompleted", surveyCompletedOne )} */}
                      {surveyCompletedOne ? (
                        <h4 style={{ color: "green" }} className="inner-title">
                          {surveycompletedMessage}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Link class="page-btn" to="/dashboard">
                              Continue
                            </Link>
                          </div>
                        </h4>
                      ) : (
                        <div
                          className="col-12"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <p>No Data Found</p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </section>
    </div>
  );
};

export default Survey;
